import { render, staticRenderFns } from "./BranchFilter.vue?vue&type=template&id=494c6696&scoped=true"
import script from "./BranchFilter.vue?vue&type=script&lang=js"
export * from "./BranchFilter.vue?vue&type=script&lang=js"
import style0 from "./BranchFilter.vue?vue&type=style&index=0&id=494c6696&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "494c6696",
  null
  
)

export default component.exports