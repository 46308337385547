<template>
    <div class="graph">
        <div class="title" v-html="title"></div>
        <pie-chart
            v-if="!onlyZero"
            :chartData="chartData" 
            :options="chartOption">
        </pie-chart>
        <div class="no-data" v-if="onlyZero">ไม่มีข้อมูล</div>
    </div>
</template>

<script>
import PieChart from '@/components/chart/Pie';

export default {
    components: {
        PieChart
    },
    data() {
        return {
            onlyZero: false,
            chartData: {
                labels: this.label,
                datasets: [
                    {
                        data: this.data,
                        backgroundColor: this.color,
                    }
                ]
            },
            chartOption: {
                responsive: true,
                tooltips: {
                    enabled: false
                },
                plugins: {
                    datalabels: {
                        formatter: (value, ctx) => {
                            const sum = ctx.dataset._meta[0].total;
                            const percentage = (value * 100 / sum).toFixed(2) + '%';

                            return percentage;
                        },
                        color: '#fff',
                    }
                }
            }
        };
    },
	props: {
        title: String,
		label: Array,
        data: Array,
        color: Array
	},
    mounted() {
        this.onlyZero = this.data.every(x => parseFloat(x) === 0);
    }
}
</script>

<style lang="scss" scoped>
.title {
    text-align: center;
    font-size: 15px;
    color: #555;
}

.no-data {
    color: #999;
    font-size: 13px;
}
</style>