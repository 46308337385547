<template>
	<div>
		<seller-branch-filter
			class="mb-3"
			labelText="คำสั่งซื้อสาขา"
			@changeBranch="changeBranch($event)">
		</seller-branch-filter>

		<h2>วิเคราะห์ธุรกิจ - Dashboard</h2>

		<div class="loading mt-3" v-if="!isContentReady">
			<loader-content :isDisplay="true"></loader-content>
		</div>

		<template v-if="isContentReady">
			<div class="form">
				<div class="date">
					<div class="input-date-icon">
						<datepicker
							v-model="fromDate"
							:input-class="[ 'input-date', 'square' ]"
							:language="calendarTH"
							:full-month-name="true"
							calendar-class="datepicker-box"
							placeholder="ตั้งแต่วันที่"
							format="dd/MM/yyyy">
						</datepicker>
						<b-icon icon="calendar"></b-icon>
					</div>

					<span>-</span>

					<div class="input-date-icon">
						<datepicker
							v-model="toDate"
							:input-class="[ 'input-date', 'square' ]"
							:language="calendarTH"
							:full-month-name="true"
							calendar-class="datepicker-box"
							placeholder="ถึงวันที่"
							format="dd/MM/yyyy">
						</datepicker>
						<b-icon icon="calendar"></b-icon>
					</div>

					<button class="btn btn-main" @click="getDashboard()">ดึงข้อมูล</button>
				</div>
			</div>

			<seller-sale-board :content="content" class="mt-3"></seller-sale-board>
			
			<div class="chart mt-4">
				<div class="row">
					<div class="col-12 col-sm-6">
						<h4>
							คำสั่งซื้อ
							<span>สัดส่วน Order สำเร็จ/ยกเลิก</span>
						</h4>
						<div class="row">
							<div class="col-6">
								<seller-doughnut-report
									:title="prevPeriod + '<br />' + '<strong>' + content.chart.order.previous.total + ' รายการ</strong>'"
									:label="[ 'คำสั่งซื้อ สำเร็จ', 'คำสั่งซื้อ ยกเลิก' ]"
									:data="content.chart.order.previous.data"
									:color="[ '#bff5d3', '#ccc' ]">
								</seller-doughnut-report>
							</div>
							<div class="col-6">
								<seller-doughnut-report
									:title="currPeriod + '<br />' + '<strong>' + content.chart.order.current.total + ' รายการ</strong>'"
									:label="[ 'คำสั่งซื้อ สำเร็จ', 'คำสั่งซื้อ ยกเลิก' ]"
									:data="content.chart.order.current.data"
									:color="[ '#bff5d3', '#ccc' ]">
								</seller-doughnut-report>
							</div>
						</div>
						<div class="box-data mt-2 mb-3">
							<div class="item">
								<label>ยกเลิกโดยร้านค้า</label>
								<span>{{ content.saleData.cancelData.seller }}</span>
							</div>
							<div class="item">
								<label>ยกเลิกโดยลูกค้า</label>
								<span>{{ content.saleData.cancelData.customer }}</span>
							</div>
						</div>
					</div>
					<div class="col-12 col-sm-6">
						<h4>
							คำสั่งซื้อ
							<span>สัดส่วน ติดตั้ง/จัดส่ง</span>
						</h4>
						<div class="row">
							<div class="col-6">
								<seller-doughnut-report
									:title="prevPeriod + '<br />' + '<strong>' + content.chart.type.previous.total + ' รายการ</strong>'"
									:label="[ 'ติดตั้ง', 'จัดส่ง' ]"
									:data="content.chart.type.previous.data"
									:color="[ '#ff5757', '#ffc107' ]"
									:total="Number(content.chart.type.previous.total)">
								</seller-doughnut-report>
							</div>
							<div class="col-6">
								<seller-doughnut-report
									:title="currPeriod + '<br />' + '<strong>' + content.chart.type.current.total + ' รายการ</strong>'"
									:label="[ 'ติดตั้ง', 'จัดส่ง' ]"
									:data="content.chart.type.current.data"
									:color="[ '#ff5757', '#ffc107' ]"
									:total="Number(content.chart.type.current.total)">
								</seller-doughnut-report>
							</div>
						</div>
						<div class="box-data mt-2">
							<div class="item">
								<label>เฉลี่ยวันติดตั้ง</label>
								<span>{{ content.saleData.avgDayService }}</span>
							</div>
							<div class="item">
								<label>เฉลี่ยวันเตรียมส่ง</label>
								<span>{{ content.saleData.avgDayDelivery }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="row mt-4">
				<div class="col-12 col-sm-8">
					<div class="chart">
						<h4>ประเภทชำระเงิน</h4>
						<seller-graph-payment-type
							:thisMonthData="content.chart.paymentType.current"
							:prevMonthData="content.chart.paymentType.previous">
						</seller-graph-payment-type>
					</div>
				</div>
				<div class="col-12 col-sm-4">
					<div class="row">
						<div class="col-6 col-sm-12">
							<seller-pie-report
								title="ประเภทรถที่ใช้บริการ"
								:label="[ 'รถยนต์นั่ง', 'รถกระบะ', 'รถตู้', 'รถ SUV', 'รันแฟลต', 'รถยนต์ไฟฟ้า' ]"
								:data="content.chart.car"
								:color="[ '#47acb1', '#f26522', '#f9aa7b', '#a5a8aa', '#add5d7', '#ffcd34' ]">
							</seller-pie-report>
						</div>
						<div class="col-6 col-sm-12">
							<div class="popular-car mt-2" v-if="content.popularCar.length > 0">
								<div class="item" v-for="(item, index) in content.popularCar" :key="index">
									<div class="type">{{ item.type }}</div>
									<p>{{ item.brandModel }}</p>
									<span>{{ item.total }}</span>
									<span :class="item.changeOperand === '+' ? 'increase' : 'decrease'">{{ item.changeOperand }}{{ item.changePercent }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<seller-product-inventory-stats class="mt-4" :content="content"></seller-product-inventory-stats>

			<seller-popular-product-sale class="mt-4" :fromDate="fromDate" :toDate="toDate" :branchId="branchId"></seller-popular-product-sale>

			<div class="row mt-4">
				<div class="col-sm-12 col-md-4">
					<seller-popular-tire category="brand" :fromDate="fromDate" :toDate="toDate" :branchId="branchId"></seller-popular-tire>
				</div>
				<div class="col-sm-12 col-md-4">
					<seller-popular-tire category="model" :fromDate="fromDate" :toDate="toDate" :branchId="branchId"></seller-popular-tire>
				</div>
				<div class="col-sm-12 col-md-4">
					<seller-popular-tire category="size" :fromDate="fromDate" :toDate="toDate" :branchId="branchId"></seller-popular-tire>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
import { th } from 'vuejs-datepicker/dist/locale';
import moment from 'moment';
import RouterPath from '@/router/path';
import Helper from '@/services/helper';
import SellerBranchFilter from '@/components/seller/BranchFilter';
import SellerSaleBoard from '@/components/seller/report/SaleBoard';
import SellerPopularProductSale from '@/components/seller/report/PopularProductSale';
import SellerDoughnutReport from '@/components/seller/report/DoughnutData';
import SellerPieReport from '@/components/seller/report/PieData';
import SellerGraphPaymentType from '@/components/seller/report/GraphPaymentType';
import SellerProductInventoryStats from '@/components/seller/report/ProductInventoryStats';
import SellerPopularTire from '@/components/seller/report/PopularTire';
import SellerService from '@/services/sellerService';

export default {
	components: {
		SellerBranchFilter,
		SellerSaleBoard,
		SellerPopularProductSale,
		SellerDoughnutReport,
		SellerPieReport,
		SellerGraphPaymentType,
		SellerProductInventoryStats,
		SellerPopularTire
	},
	data() {
		return {
			calendarTH: th,
			fromDate: new Date(new Date().setDate((new Date()).getDate() - 7)),
			toDate: new Date(),
			prevPeriod: '',
			currPeriod: '',
			branchId: Helper.getBranchSellerId(),
			content: null,
			isContentReady: false
		}
	},
	mounted() {
		const sellerInfo = JSON.parse(localStorage.shopInfo);
		this.branchId = sellerInfo.sellerId;

		this.getDashboard();
	},
	methods: {
		async getDashboard() {
			const fromDate = moment(this.fromDate);
			const toDate = moment(this.toDate);
			const dateDiff = toDate.diff(fromDate, 'days');
			const prevToDate = moment(fromDate).subtract(1, 'days');
			const prevFromDate = moment(prevToDate).subtract(dateDiff, 'days');

			this.prevPeriod = prevFromDate.format('DD/MM/YYYY') + ' - ' + prevToDate.format('DD/MM/YYYY');
			this.currPeriod = fromDate.format('DD/MM/YYYY') + ' - ' + toDate.format('DD/MM/YYYY');

			this.isContentReady = false;
			this.resultList = [];

            const result = await SellerService.getDashboard(this.fromDate, this.toDate, this.branchId);

			if (result.status == 401) {
				this.$router.push(RouterPath.SELLER_LOGIN);
			}

			this.isContentReady = true;
			this.content = result.data;
		},
		changeBranch(branchId) {
			this.branchId = branchId;
			this.getDashboard();
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

h2 {
	font-size: 24px;
	font-family: 'Open Sans', 'Kanit';
	font-weight: bold;
	margin-bottom: 10px;
	color: #333;
}

.loading {
	height: 500px;
}

.form {
	.date {
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		.input-date-icon {
			width: 200px;

			@media only screen and (max-width: $screenSmall) {
				width: 150px;
			}

			@media only screen and (max-width: $screenExtraSmall) {
				width: 46%;
			}
		}

		span {
			margin: 0 10px;

			@media only screen and (max-width: $screenExtraSmall) {
				width: 8%;
				text-align: center;
				margin: 0;
			}
		}

		.btn {
			margin-left: 15px;
			height: 42px;
			border: 1px solid #666;

			@media only screen and (max-width: $screenSmall) {
				height: 38px;
			}

			@media only screen and (max-width: $screenExtraSmall) {
				margin-top: 5px;
				margin-left: 0;
				width: 100%;
			}
		}
	}
}

.box-data {
	display: flex;
	border: 2px solid #cc0000;
	background: #fed966;

	.item {
		flex: 1;
		border-right: 2px solid #cc0000;
		padding: 5px;
		text-align: center;

		&:last-child {
			border-right: none;
		}

		label {
			display: block;
			font-size: 13px;
			line-height: 15px;
		}

		span {
			display: block;
			font-size: 18px;
			font-weight: bold;
		}
	}
}

::v-deep .form {
	.input-date-icon {
		.input-date {
			padding: 8px 15px;
			border: 1px solid #666;

			@media only screen and (max-width: $screenSmall) {
				font-size: 14px;
			}
		}
	}
}

.chart {
	h4 {
		padding: 10px;
		background: #eee;
		font-weight: bold;
		font-size: 14px;
		font-family: 'Open Sans', 'Kanit';

		span {
			font-family: 'Open Sans', 'Kanit';
		}
	}
}

.popular-car {
	display: flex;
	flex-direction: column;

	.item {
		padding: 5px;
		position: relative;
		text-align: center;
		border: 1px solid #666;
		
		& + .item {
			margin-top: 5px;
		}

		.type {
			position: absolute;
			transform-origin: 0 0;
			@include transform(rotate(90deg));
			top: 5px;
			left: 16px;
			font-size: 11px;
			color: #999;
		}

		p {
			font-size: 14px;
			margin-bottom: 5px;
		}

		span {
			display: block;
			font-size: 14px;

			&.increase {
				color: #19ada1;
			}

			&.decrease {
				color: #cc0000;
			}
		}
	}
}
</style>