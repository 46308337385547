export default {
	data() {
		return {
			isResultReady: false,
			resultList: []
		}
	},
	props: {
		fromDate: Date,
		toDate: Date,
		branchId: Number
	}
}