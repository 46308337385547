<template>
	<section>
		<div class="form">
			<h2 v-if="category === 'brand'">ยี่ห้อยาง ขายมากที่สุด</h2>
			<h2 v-if="category === 'model'">รุ่นยาง ขายมากที่สุด</h2>
			<h2 v-if="category === 'size'">ขนาดยาง ขายมากที่สุด</h2>
		</div>

		<div class="loading mt-1" v-if="!isResultReady">
			<loader-content :isDisplay="true"></loader-content>
		</div>

		<div class="vue-dragscoll mt-1" v-dragscroll.x @dragscrollstart="dragStart()" @dragscrollend="dragEnd()" @click.capture="dragClick($event)" v-if="isResultReady">
			<table>
				<thead>
					<tr>
						<th>อันดับ</th>
						<th v-if="category === 'brand'">ยี่ห้อ</th>
						<th v-if="category === 'model'">รุ่น</th>
						<th v-if="category === 'size'">ขนาด</th>
						<th>จำนวน</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(item, index) in resultList" :key="index">
						<td>{{ item.ranking }}</td>
						<td>{{ item.text }}</td>
						<td>{{ item.total }}</td>
					</tr>
				</tbody>
			</table>
		</div>

		<div class="no-data" v-if="isResultReady && resultList.length === 0">
			<b-icon icon="box-seam"></b-icon>
			<span>ไม่พบรายการ</span>
		</div>
	</section>
</template>

<script>
import MixinPopularTireRanking from '@/components/seller/mixins/popularTireRanking';
import MixinDragScroll from '@/mixins/dragScroll';
import SellerService from '@/services/sellerService';

export default {
	mixins: [ MixinPopularTireRanking, MixinDragScroll ],
	props: {
		category: String
	},
	watch: {
		branchId() {
			this.getRankingPopularTire();
		},
	},
	mounted() {
		this.getRankingPopularTire();
	},
	methods: {
		async getRankingPopularTire() {
			this.isResultReady = false;
			this.resultList = [];
			
            const result = await SellerService.getRankingPopularTire(this.category, this.fromDate, this.toDate, this.branchId);

			this.isResultReady = true;
			this.resultList = result.data.resultList;
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/components/seller/style/DashboardRanking.scss';
</style>