<template>
	<section>
		<div class="form main">
			<h2>สินค้าขายดี ตามยอดขาย</h2>
		</div>

		<div class="loading mt-1" v-if="!isResultReady">
			<loader-content :isDisplay="true"></loader-content>
		</div>

		<div class="vue-dragscoll mt-1" v-dragscroll.x @dragscrollstart="dragStart()" @dragscrollend="dragEnd()" @click.capture="dragClick($event)" v-if="isResultReady">
			<table>
				<thead>
					<tr>
						<th>อันดับ</th>
						<th>ขนาด</th>
						<th>ยี่ห้อ</th>
						<th>รุ่น</th>
						<th>ยอดขาย (บาท)</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(item, index) in resultList" :key="index">
						<td>{{ item.ranking }}</td>
						<td>{{ item.size }}</td>
						<td>{{ item.brand }}</td>
						<td>{{ item.model }}</td>
						<td>{{ item.sale }}</td>
					</tr>
				</tbody>
			</table>
		</div>

		<div class="no-data" v-if="isResultReady && resultList.length === 0">
			<b-icon icon="box-seam"></b-icon>
			<span>ไม่พบรายการ</span>
		</div>
	</section>
</template>

<script>
import MixinPopularTireRanking from '@/components/seller/mixins/popularTireRanking';
import MixinDragScroll from '@/mixins/dragScroll';
import SellerService from '@/services/sellerService';

export default {
	mixins: [ MixinPopularTireRanking, MixinDragScroll ],
	watch: {
		branchId() {
			this.getDashboardRankingSale();
		},
	},
	mounted() {
		this.getDashboardRankingSale();
	},
	methods: {
		async getDashboardRankingSale() {
			this.isResultReady = false;
			this.resultList = [];
			
            const result = await SellerService.getDashboardRankingSale(this.fromDate, this.toDate, this.branchId);

			this.isResultReady = true;
			this.resultList = result.data.resultList;
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/components/seller/style/DashboardRanking.scss';

.form {
	@media only screen and (max-width: $screenSmall) {
		display: block;
	}

	h2 {
		@media only screen and (max-width: $screenSmall) {
			text-align: center;
			width: 100%;
			font-size: 15px;
			margin-bottom: 10px;
		}
	}

	.date {
		@media only screen and (max-width: $screenSmall) {
			width: 100%;
		}

		@media only screen and (max-width: $screenExtraSmall) {
			display: block;
		}
		
		.input-date-icon {
			@media only screen and (max-width: $screenSmall) {
				flex: 1;
				font-size: 12px;
				width: 100%;
			}
		}

		span {
			@media only screen and (max-width: $screenSmall) {
				text-align: right;
				margin-right: 10px;
				font-size: 12px;
				width: 75px;
			}
		}
	}
}
</style>