<template>
    <div class="graph">
        <div class="title" v-html="title"></div>
        <doughnut-chart
            v-if="!onlyZero"
            :chartData="chartData" 
            :options="chartOption">
        </doughnut-chart>
        <div class="no-data" v-if="onlyZero">ไม่มีข้อมูล</div>
    </div>
</template>

<script>
import DoughnutChart from '@/components/chart/Doughnut';

export default {
    components: {
        DoughnutChart
    },
    data() {
        return {
            onlyZero: false,
            chartData: {
                labels: this.label,
                datasets: [
                    {
                        data: this.data,
                        backgroundColor: this.color,
                    }
                ]
            },
            chartOption: {
                responsive: true
            }
        };
    },
	props: {
        title: String,
		label: Array,
        data: Array,
        color: Array
	},
    mounted() {
        this.onlyZero = this.data.every(x => parseFloat(x) === 0);
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.title {
    text-align: center;
    font-size: 15px;
    color: #555;

    @media only screen and (max-width: $screenMedium) {
        font-size: 12px;
    }
}

.no-data {
    color: #999;
    font-size: 13px;
}
</style>