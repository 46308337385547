<template>
	<div class="products-stat">
		<div class="title-bar">สินค้าและโปรโมชั่น</div>

		<template v-if="content.productData.length > 0">
			<div class="card-data-container">
				<div class="item" v-for="(item, index) in content.productData" :key="index">
					<div class="category">{{ item.category }}</div>
					<div class="info">
						<div class="card-data" v-for="(card, cardIndex) in item.data" :key="cardIndex">
							<div class="data">
								<label>{{ card.name }}</label>
								<p>{{ card.total }} <span class="unit">SKU</span> <span v-if="card.percentage">({{ card.percentage }}%)</span></p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>
		<div class="no-data" v-else>
			<b-icon icon="box-seam"></b-icon>
			<span>ยังไม่มีข้อมูล</span>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		content: Object
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.products-stat {
	.title-bar {
		padding: 10px;
		background: #fbac53;
		font-weight: bold;
		font-size: 14px;
		font-family: 'Open Sans', 'Kanit';
	}

	.card-data-container {
		margin-top: 15px;

		.item {
			display: flex;
			padding-bottom: 5px;
			margin-bottom: 10px;
			border-bottom: 1px dashed #ddd;

			.category {
				text-align: right;
				font-size: 13px;
				font-weight: bold;
				color: #b15a4b;
				width: 130px;
				padding-top: 5px;
				padding-right: 20px;
			}

			.info {
				display: flex;
				flex-wrap: wrap;
				flex: 1;

				.card-data {
					background: #fdf2d7;
					margin-right: 5px;
					margin-bottom: 5px;
					padding: 5px;
					width: 160px;
					height: 60px;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					border: 1px solid #f3d98a;

					.data {
						width: 100%;
						text-align: center;
						overflow: hidden;

						label {
							display: block;
							font-size: 13px;
							margin-bottom: 2px;
							white-space: nowrap;
						}

						p {
							display: block;
							font-size: 15px;
							line-height: 24px;
							color: #333;
							font-weight: bold;
							margin: 0;

							span {
								font-size: 13px;
								font-weight: normal;

								&.unit {
									font-size: 11px;
									line-height: 11px;
									opacity: 0.6;
								}
							}
						}
					}
				}
			}
		}
	}
}
</style>