<template>
	<div class="branch-filter" v-if="hasBranch">
		<div class="input-field">
			<div class="loading" v-if="!isResultReady">
				<loader-spinner
					sizeWidth="20px"
					sizeHeight="20px">
				</loader-spinner>
			</div>

			<template v-if="isResultReady">
				<label>{{ labelText }}</label>
				<dropdown
					v-model="branchId"
					placeholder="-- เลือกสาขา --"
					:options="optionBranch"
					:classes="{ input: ['square'] }">
				</dropdown>
				<button class="btn btn-danger square" @click="changeBranch()">เลือก</button>
			</template>
		</div>
	</div>
</template>

<script>
import SellerService from '@/services/sellerService';
import LoaderSpinner from '@/components/utility/LoaderSpinner';

export default {
	components: {
		LoaderSpinner
	},
	data() {
		return {
			optionBranch: [],
            isResultReady: false,
            hasBranch: false,
			branchId: ''
		}
	},
	props: {
		labelText: String
	},
	async mounted() {
		const sellerInfo = JSON.parse(localStorage.shopInfo);
		
		this.branchId = sellerInfo.sellerId;

		if (sellerInfo.isShowBranch) {
			this.hasBranch = true;
			
			this.getBranchList();
		}
	},
	methods: {
		async getBranchList() {
            this.isResultReady = false;
            const result = await SellerService.getBranchList('', -1);

			this.optionBranch.push({
				id: -1,
				name: 'ดูทั้งหมด'
			});

			if (result.data.branchList.length > 0) {
				result.data.branchList.forEach((item) => {
					this.optionBranch.push({
						id: item.sellerId,
						name: item.name
					});
				});
            }

			this.isResultReady = true;
		},
		changeBranch() {
			if (this.branchId) {
				this.$emit('changeBranch', this.branchId);
			}
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.branch-filter {
	border-bottom: 1px dashed #ddd;
	padding-bottom: 15px;

	.input-field {
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		@media only screen and (max-width: $screenExtraSmall) {
			display: block;
			text-align: center;
		}

		label {
			margin-right: 15px;

			@media only screen and (max-width: $screenExtraSmall) {
				margin-right: 0;
			}
		}

		.btn {
			height: 37px;
		}
	}
}

::v-deep .form-option {
	width: 300px;
	margin-right: 5px;

	@media only screen and (max-width: $screenExtraSmall) {
		margin-right: 0;
		margin-bottom: 5px;
		width: 100%;
	}

	input[readonly] {
		background: #fff;
	}
}
</style>