<template>
	<div class="stats-record">
		<div class="vue-dragscoll" v-dragscroll.x @dragscrollstart="dragStart()" @dragscrollend="dragEnd()" @click.capture="dragClick($event)">
			<table>
				<thead>
					<tr>
						<th class="text-center">ผู้เข้าชมร้านค้า</th>
						<th class="text-center">กดใส่ตะกร้า</th>
						<th class="text-center">ลูกค้า</th>
						<th class="text-center">ยอดขาย (บาท)</th>
						<th class="text-center">ค่าเฉลี่ยต่อบิล (บาท)</th>
						<th class="text-center">ยอดขาย (เส้น)</th>
						<th class="text-center">ค่าเฉลี่ยต่อบิล (เส้น)</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>{{ content.saleData.visitors }}</td>
						<td>{{ content.saleData.addCart }}</td>
						<td>{{ content.saleData.customers }}</td>
						<td>{{ content.saleData.saleRevenue }}</td>
						<td>{{ content.saleData.avgTranPrice }}</td>
						<td>{{ content.saleData.saleQuantity }}</td>
						<td>{{ content.saleData.avgUnitPerCust }}</td>
					</tr>
					<tr>
						<td colspan="2"><label>ติดตั้ง</label></td>
						<td>{{ content.saleData.installCustomers }}</td>
						<td>{{ content.saleData.installSaleRevenue }}</td>
						<td>{{ content.saleData.installAvgTranPrice }}</td>
						<td>{{ content.saleData.installSaleQuantity }}</td>
						<td>{{ content.saleData.installAvgUnitPerCust }}</td>
					</tr>
					<tr>
						<td colspan="2"><label>จัดส่ง</label></td>
						<td>{{ content.saleData.deliveryCustomers }}</td>
						<td>{{ content.saleData.deliverySaleRevenue }}</td>
						<td>{{ content.saleData.deliveryAvgTranPrice }}</td>
						<td>{{ content.saleData.deliverySaleQuantity }}</td>
						<td>{{ content.saleData.deliveryAvgUnitPerCust }}</td>
					</tr>
				</tbody>
			</table>
		</div>

		<div class="title-bar">จำนวนผู้เข้าชมสินค้า</div>

		<template v-if="content.visitorData">
			<div class="vue-dragscoll" v-dragscroll.x @dragscrollstart="dragStart()" @dragscrollend="dragEnd()" @click.capture="dragClick($event)">
				<table>
					<thead>
						<tr>
							<th class="text-center"></th>
							<th class="text-center">จำนวน</th>
							<th class="text-center" v-for="(column, index) in content.visitorData.head" :key="index">{{ column }}</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td><label>ผู้เข้าชมสินค้า</label></td>
							<td>{{ content.saleData.totalViewProducts }}</td>
							<td v-for="(column, visitorIndex) in content.visitorData.visitors" :key="visitorIndex">{{ column }}</td>
						</tr>
						<tr>
							<td><label>กดใส่ตะกร้า</label></td>
							<td>{{ content.saleData.addCart }}</td>
							<td v-for="(column, addCartIndex) in content.visitorData.addCart" :key="addCartIndex">{{ column }}</td>
						</tr>
						<tr>
							<td><label>ลูกค้า</label></td>
							<td>{{ content.saleData.customers }}</td>
							<td v-for="(column, customerIndex) in content.visitorData.customers" :key="customerIndex">{{ column }}</td>
						</tr>
					</tbody>
				</table>
			</div>

			<div class="row mt-3">
				<div class="col-sm-6 col-md-3">
					<seller-pie-report
						title="ติดตั้ง/จัดส่ง"
						:label="[ 'ติดตั้ง', 'จัดส่ง' ]"
						:data="[ content.saleData.installCustomers, content.saleData.deliveryCustomers ]"
						:color="[ '#47acb1', '#f26522' ]">
					</seller-pie-report>
				</div>
				<div class="col-sm-6 col-md-3">
					<seller-pie-report
						title="ผู้เข้าชมสินค้า"
						:label="content.visitorData.head"
						:data="content.visitorData.visitors"
						:color="content.visitorData.colors">
					</seller-pie-report>
				</div>
				<div class="col-sm-6 col-md-3">
					<seller-pie-report
						title="กดใส่ตะกร้า"
						:label="content.visitorData.head"
						:data="content.visitorData.addCart"
						:color="content.visitorData.colors">
					</seller-pie-report>
				</div>
				<div class="col-sm-6 col-md-3">
					<seller-pie-report
						title="ลูกค้า"
						:label="content.visitorData.head"
						:data="content.visitorData.customers"
						:color="content.visitorData.colors">
					</seller-pie-report>
				</div>
			</div>
		</template>
		<div class="no-data" v-else>
			<b-icon icon="box-seam"></b-icon>
			<span>ยังไม่มีข้อมูล</span>
		</div>
	</div>
</template>

<script>
import SellerPieReport from '@/components/seller/report/PieData';

export default {
	components: {
		SellerPieReport
	},
	data() {
		return {
			option: 'week'
		}
	},
	props: {
		content: Object
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.stats-record {
	table {
		width: 100%;
		font-size: 13px;
		line-height: 15px;

		thead {
			th {
				background: #fbac53;
				padding: 13px 10px;
				min-width: 100px;
				text-align: right;
				font-size: 13px;
				font-weight: normal;
				border: 1px solid #cc0000;
				vertical-align: middle;
			}
		}

		tbody {
			td {
				border: 1px solid #cc0000;
				background: #fed966;
				padding: 10px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				max-width: 400px;
				text-align: right;
				font-size: 16px;
				font-weight: bold;
				vertical-align: middle;

				label {
					font-size: 13px;
					font-weight: bold;
				}
			}
		}

		tfoot {
			td {
				padding: 10px;
				font-size: 17px;
				font-weight: bold;
				border-bottom: 1px solid #999;
				background: #f5f5f5;

				@media only screen and (max-width: $screenSmall) {
					font-size: 14px;
					line-height: 16px;
				}
			}
		}
	}

	.title-bar {
		margin: 20px 0 10px;
		font-weight: bold;
		font-family: 'Open Sans', 'Kanit';
		font-size: 16px;
	}
}
</style>