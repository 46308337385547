<template>
    <div class="graph">
        <bar-chart
            :chartData="chartData" 
            :options="chartOption">
        </bar-chart>
    </div>
</template>

<script>
import BarChart from '@/components/chart/Bar';

export default {
    components: {
        BarChart
    },
	props: {
        thisMonthData: Array,
        prevMonthData: Array
	},
    data() {
        return {
            chartData: {
                labels: [ 'บัตรเครดิต/เดบิต CARD', 'Mobile Banking', 'ผ่อนมีดอกเบี้ย', 'ผ่อน 0%' ],
                datasets: [
                    {
                        label: 'เดือนนี้ (%)',
                        data: this.thisMonthData,
                        backgroundColor: '#bff5d3',
                    },
                    {
                        label: 'เดือนก่อนหน้า (%)',
                        data: this.prevMonthData,
                        backgroundColor: '#fbac53',
                    },
                ]
            },
            chartOption: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                        ticks: { beginAtZero: true }
                    }]
                },
                tooltips: {
                    callbacks: {
                        label: (item) => `${item.yLabel} %`,
                    },
                },
            }
        };
    }
}
</script>